<script setup lang="ts">
import moment from 'moment'
import type { Article } from '~/types/api/Article'

const props = defineProps<{
  article: Article
  isInSlider?: boolean
}>()

const articleLocaleLink = useLocaleLink({
  name: 'news-article-slug-id',
  params: {
    slug: props.article.slug,
    id: props.article.id,
  },
})

const firstTagName = computed(() => {
  if (!props.article.tags.length) {
    return null
  }

  return props.article.tags[0].label
})

const imageData = getImageSrcSizesData(
  props.article.thumbnail || props.article.image,
  {
    defaultFormat: '384x768',
    sizes: `
      (max-width: 575.99px) 90vw,
      (max-width: 991.99px) 47vw,
      (max-width: 1199.99px) 31.1vw,
      (max-width: 1399.99px) 340px,
      384px
    `,
    srcsetToGenerate: [
      ['340x680', '340w'],
      ['680x1360', '680w'],

      ['384x768', '384w'],
      ['768x1536', '768w'],
    ],
  }
)
</script>

<template>
  <article class="c-news-thumb">
    <div class="c-news-thumb__image">
      <img
        :src="imageData.src"
        :srcset="imageData.srcset"
        :sizes="imageData.sizes"
        :alt="article.name"
        loading="lazy"
      />
    </div>

    <div v-if="firstTagName" class="c-news-thumb__hashtag">
      #{{ firstTagName }}
    </div>

    <h2 class="c-news-thumb__title h4">{{ article.name }}</h2>

    <div class="c-news-thumb__info">
      <span
        v-if="article.show_publish_date && article.publish_date"
        class="sc-item"
        >{{ moment(article.publish_date).format('DD.MM.YYYY') }}</span
      >

      <span class="sc-item">{{
        $t(
          'pages.news.readingTime',
          { _0: article.reading_time },
          article.reading_time
        )
      }}</span>
    </div>

    <div class="c-news-thumb__description" v-html="article.short_description" />

    <div class="c-news-thumb__link">
      <NuxtLink
        class="c-link c-link--with-leading-bar c-link--block-hover"
        :to="articleLocaleLink"
        >{{ $t('pages.news.thumb.showDetail') }}</NuxtLink
      >
    </div>
  </article>
</template>

<style lang="scss" scoped>
@use '@inkline/inkline/css/mixins' as *;

.c-news-thumb {
  --news-thumb--title--color: var(--color-primary);

  width: 100%;
  height: 100%;
  position: relative;
  min-width: 0;
  display: flex;
  flex-direction: column;

  @media (hover: hover) {
    &:hover {
      --news-thumb--title--color: var(--color-secondary);
    }
  }

  &__image {
    width: 100%;
    aspect-ratio: 3 / 2;
    flex: 0 0 auto;
    min-height: 0;
    overflow: hidden;
    margin-bottom: 22px;
    background-color: var(--color-solid-grey);

    @include breakpoint-down('sm') {
      margin-bottom: 16px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__hashtag {
    font-size: 18px;
    font-weight: 500;
    color: var(--color-secondary);
    margin-bottom: 8px;

    @include breakpoint-down('sm') {
      font-size: 16px;
      margin-bottom: 6px;
    }
  }

  &__title {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.1;
    margin-bottom: 8px;
    color: var(--news-thumb--title--color);
    transition: color var(--transition-duration)
      var(--transition-timing-function);

    @include breakpoint-down('sm') {
      font-size: 22px;
    }
  }

  &__info {
    display: flex;
    gap: 8px;
    margin-bottom: 24px;

    @include breakpoint-down('sm') {
      margin-bottom: 18px;
    }

    span {
      color: var(--color-solid-grey-dark);
      font-size: 18px;
      line-height: 1;

      @include breakpoint-down('sm') {
        font-size: 16px;
      }

      &:not(:last-child) {
        padding-right: 8px;
        border-right: 1px solid var(--color-border);
      }
    }
  }

  &__description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
    margin-bottom: 24px;

    & > :deep(*):last-child {
      margin-bottom: 0;
    }

    @include breakpoint-down('sm') {
      font-size: 16px;
      line-height: 1.6;
      margin-bottom: 16px;
    }
  }

  &__link {
    margin-top: auto;
  }
}
</style>
